import React from "react";
import headerStyles from "../styles/HeaderStyle.module.css";
import twogether from "../data/twogether.png";

export default class HeaderComponent extends React.PureComponent {

    render() {
        return (
            <div className={headerStyles.header }>
                <div>
                    <h1 className={headerStyles.welcome}>Willkommen bei
                    </h1>
                    <img
                        src={twogether}
                        alt={""}
                        className={headerStyles.pageLogo2Gether}/>
                </div>
            </div>
        );
    }
}
