import React from "react";
import signUpCompleteStyles from "../../styles/SignupCompleteStyle.module.css"
import {Link} from "react-router-dom";
import {ROUTE_BASE} from "../../control/routes";

export default class SignupCompletion extends React.PureComponent {

    render() {
        return (
            <div className={signUpCompleteStyles.container}>
                <h2>Erfolgreich!</h2>
                <p>Du musst nur noch auf deine Freischaltung warten. Ein Admin wurde bereits informiert.</p>
                <p>Viel Spaß mit 2Gether!</p>
                <Link
                    className={signUpCompleteStyles.home}
                    to={ROUTE_BASE}>
                    Zur Startseite
                </Link>
            </div>
        );
    }

}
