import React from "react";
import SignupForm from "./SignupForm";
import SignupConfirmation from "./SignupConfirmation";
import SignupCompletion from "./SignupCompletion";

enum SignupState {
    SIGNUP_SIGNUP ,
    SIGNUP_CONFIRM,
    SIGNUP_COMPLETE
}

interface State {
    username: string
    signupState: SignupState
}

export default class SignupComponent extends React.PureComponent<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            username: "",
            signupState: SignupState.SIGNUP_SIGNUP
        }
        this.signupComplete = this.signupComplete.bind(this);
        this.confirmationComplete = this.confirmationComplete.bind(this);
    }

    render() {
        switch (this.state.signupState) {
            case SignupState.SIGNUP_SIGNUP:
                return <SignupForm stageComplete={this.signupComplete}/>;
            case SignupState.SIGNUP_CONFIRM:
                return <SignupConfirmation
                    username={this.state.username}
                    stageComplete={this.confirmationComplete}/>;
            case SignupState.SIGNUP_COMPLETE:
                return <SignupCompletion />;
        }
    }

    private signupComplete(username: string) {
        this.setState({
            username: username,
            signupState: SignupState.SIGNUP_CONFIRM
        });
    }

    private confirmationComplete() {
        this.setState({signupState: SignupState.SIGNUP_COMPLETE});
    }

}