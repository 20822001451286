import React from "react";
import {Box, Button, CircularProgress, Input} from "@material-ui/core";
import {Auth} from "aws-amplify";
import {Link} from "react-router-dom";
import {ROUTE_BASE} from "../../control/routes";

import baseStyles from "../../styles/BaseStyle.module.css"
import signUpFromStyles from "../../styles/SignupFormStyle.module.css"
import {
    validateEmail,
    validatePasswords,
    validFirstName,
    validLastName,
    validMattermostUsername
} from "../../control/validation";
import {getCustomErrorMessage} from "../../control/ErrorHandler";

interface Props {
    stageComplete: (username: string) => void
}

interface State {
    firstName: string
    lastName: string
    mattermostName: string
    email: string
    password: string
    passwordConfirm: string
    errorMessage: string
    loading: boolean
    buttonEnabled: boolean
}

export default class SignupForm extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            mattermostName: "",
            email: "",
            password: "",
            passwordConfirm: "",
            errorMessage: "",
            loading: false,
            buttonEnabled: true
        }
        this.renderLoading = this.renderLoading.bind(this);
        this.renderError = this.renderError.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePasswordConfirmChange = this.handlePasswordConfirmChange.bind(this);
        this.onAccountCreateClicked = this.onAccountCreateClicked.bind(this);
        this.validateInputs = this.validateInputs.bind(this);
    }

    render() {
        return (
            <React.Fragment>
                <div className={signUpFromStyles.container}>
                    <form autoComplete={"off"}>
                        <div>
                            <label className={signUpFromStyles.label}>Vorname *</label>
                            <Box width={300}>
                                <Input
                                    className={"input"}
                                    fullWidth={true}
                                    onChange={evt => this.handleFirstNameChange(evt.target.value)}
                                    type="text"
                                    placeholder="Vorname"
                                    required={true}/>
                            </Box>
                        </div>
                        <div>
                            <label className={signUpFromStyles.label}>Nachname *</label>
                            <Box width={300}>
                                <Input
                                    className={"input"}
                                    fullWidth={true}
                                    onChange={evt => this.handleLastNameChange(evt.target.value)}
                                    type="text"
                                    placeholder="Nachname"
                                    required={true}/>
                            </Box>
                        </div>
                        <div>
                            <label className={signUpFromStyles.label}>E-Mail *</label>
                            <Box width={300}>
                                <Input
                                    className={"input"}
                                    fullWidth={true}
                                    onChange={evt => this.handleEmailChange(evt.target.value)}
                                    type="email"
                                    value={this.state.email}
                                    placeholder="E-Mail"
                                    required={true}/>
                            </Box>
                        </div>
                        <div>
                            <label className={signUpFromStyles.label}>Passwort *</label>
                            <Box width={300}>
                                <Input
                                    className={"input"}
                                    fullWidth={true}
                                    onChange={evt => this.handlePasswordChange(evt.target.value)}
                                    type="password"
                                    placeholder="Passwort"
                                    required={true}/>
                            </Box>
                        </div>
                        <div>
                            <label className={signUpFromStyles.label}>Passwort wiederholen *</label>
                            <Box width={300}>
                                <Input
                                    className={"input"}
                                    fullWidth={true}
                                    onChange={evt => this.handlePasswordConfirmChange(evt.target.value)}
                                    type="password"
                                    placeholder="Passwort wiederholen"
                                    required={true}/>
                            </Box>
                        </div>
                        <div className={signUpFromStyles.message}>
                            {this.renderError()}
                            {this.renderLoading()}
                        </div>
                        <Box
                            display="flex"
                            justifyContent="center">
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                disabled={!this.state.buttonEnabled}
                                className={"button"}
                                onClick={this.onAccountCreateClicked}>
                                Account Erstellen
                            </Button>
                        </Box>
                        <Link
                            className={baseStyles.link}
                            to={ROUTE_BASE}>
                            Zurück zur Startseite
                        </Link>
                    </form>
                </div>
            </React.Fragment>
        );
    }

    private renderLoading() {
        if (!this.state.loading)
            return null;
        return (
            <span className={baseStyles.loadingInfo}>
                <CircularProgress
                    className={baseStyles.progress}
                    color="inherit"
                    size={20}/>
                Verarbeite...
            </span>
        )
    }

    private renderError() {
        if (this.state.errorMessage === "")
            return null;
        return (
            <p className={signUpFromStyles.error}>
                {this.state.errorMessage}
            </p>
        );
    }

    private handleFirstNameChange(firstName: string) {
        this.setState({
            firstName: firstName
        });
    }

    private handleLastNameChange(lastName: string) {
        this.setState({
            lastName: lastName
        });
    }

    private handleEmailChange(email: string) {
        this.setState({
            email: email
        });
    }

    private handlePasswordChange(password: string) {
        this.setState({
            password: password
        });
    }

    private handlePasswordConfirmChange(password: string) {
        this.setState({
            passwordConfirm: password
        });
    }

    private onAccountCreateClicked() {
        const err = this.validateInputs();
        if (err !== null) {
            const errorMessage=getCustomErrorMessage(err)
            this.setState({
                errorMessage: errorMessage
            });
        } else {
            this.setState({
                errorMessage: "",
                loading: true,
                buttonEnabled: false
            });
            Auth.signUp({
                username: this.state.email.toLowerCase(),
                password: this.state.password,
                attributes: {
                    "given_name": this.state.firstName,
                    "family_name": this.state.lastName,
                    "custom:mattermostusername": ""
                }
            }).then(() => {
                this.props.stageComplete(this.state.email);
            }).catch(err => {
                const errorMessage=getCustomErrorMessage(err)
                this.setState({
                    buttonEnabled: true,
                    loading: false,
                    errorMessage: `Es ist ein Fehler aufgetreten: ${errorMessage}`
                });
            });
        }
    }

    private validateInputs(): Error | null {
        try {
            validFirstName(this.state.firstName)
            validLastName(this.state.lastName)
            validMattermostUsername(this.state.mattermostName)
            validateEmail(this.state.email)
            validatePasswords(this.state.password, this.state.passwordConfirm)
        } catch (e) {
            return e
        }
        return null;
    }

}

