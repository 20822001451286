import React from "react";
import {Link} from "react-router-dom"
import {ROUTE_SIGNUP} from "../control/routes";
import footerStyles from "../styles/FooterStyle.module.css"

export default class FooterComponent extends React.PureComponent {

    render() {
        return (
            <div className={footerStyles.footer}>
                <span className={footerStyles.text}>Noch keinen 2Gether-Account? Hier </span>
                <Link to={ROUTE_SIGNUP} className={footerStyles.register}>registrieren</Link>
                <span className={footerStyles.text}>!</span>
            </div>
        )
    }

}
