import queryString from "querystring";

export function getQueryParams(): Map<string, string> {
    const query = window.location.search;
    if (query.length <= 0)
        return new Map();
    const parsed = queryString.parse(query);
    const params = new Map<string, string>();
    for (let key in parsed) {
        if (!parsed.hasOwnProperty(key))
            continue;
        const value = parsed[key];
        if (typeof value !== "string")
            continue;
        key = key.replace("?", "");
        params.set(key, value);
    }
    return params;
}


export function queryParametersPresent(): boolean {
    return document.location.search.length > 0;
}