import {getQueryParams} from "./query";
import {QueryParamsInvalidError} from "./Errors";

const PARAM_USERID = "userID";
const PARAM_TOKEN = "token";
const PARAM_ORIGIN_URL = "originURL";

export interface ChangePasswordQueryParams {
    userID: string
    token: string
    originURL: string
}

export function extractQueryParameters(): ChangePasswordQueryParams {
    const params = getQueryParams();
    try {
        validateQueryParams(params)
        return {
            userID: params.get(PARAM_USERID)!!,
            token: params.get(PARAM_TOKEN)!!,
            originURL: params.get(PARAM_ORIGIN_URL)!!,
        }
    } catch (err) {
        throw err
    }
}

function validateQueryParams(params: Map<string, string>) {
    const awsStandardCodeRegExp = RegExp("^[0-9]{6}$")
    const isStandardAwsCode = awsStandardCodeRegExp.test(params.get(PARAM_TOKEN)!!)
    const hasAllParams = params.size === 3;
    const areParamsValid = hasAllParams && isStandardAwsCode && params.has(PARAM_USERID) && params.has(PARAM_ORIGIN_URL)
    if (!areParamsValid)
        throw new QueryParamsInvalidError()
}

