import ProjectInfo from "../model/ProjectInfo";
import BundleProjectInfoLoader from "./BundleProjectInfoLoader";

export interface ProjectInfoLoader {
    loadProjectInfos(): Promise<ProjectInfo[]>
    getProjectPicturePath(info: ProjectInfo): string
}

const defaultLoader: ProjectInfoLoader = new BundleProjectInfoLoader()

export function loadProjectInfos(): Promise<ProjectInfo[]> {
    return defaultLoader.loadProjectInfos();
}

export function getProjectPicturePath(info: ProjectInfo): string {
    return defaultLoader.getProjectPicturePath(info);
}

