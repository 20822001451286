import React from "react";
import {Box, Button, CircularProgress, Input} from "@material-ui/core";
import {Auth} from "aws-amplify"
import signUpConfirmStyles from "../../styles/SignupConfirmStyle.module.css"

interface Props {
    username: string
    stageComplete: () => void
}

interface State {
    confirmationCode: string
    infoMessage: string
    errorMessage: string
    loading: boolean
    buttonEnabled: boolean
}

const VERIFICATION_CODE_LENGTH = 6;
const ERRORCODE_INVALID_CODE = "CodeMismatchException";

export default class SignupConfirmation extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            confirmationCode: "",
            infoMessage: "",
            errorMessage: "",
            loading: false,
            buttonEnabled: false
        };
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.onCodeSubmit = this.onCodeSubmit.bind(this);
    }

    render() {
        return (
            <React.Fragment>
                <div className={signUpConfirmStyles.container}>
                    <h3>Bitte gib den Bestätigungscode ein, den du per E-Mail erhalten hast!</h3>
                    <form autoComplete={"off"}>
                        <div>
                            <label className={signUpConfirmStyles.label}>Bestätigungscode</label>
                            <Box width={300}>
                                <Input
                                    className={"input"}
                                    fullWidth={true}
                                    value={this.state.confirmationCode}
                                    onChange={evt => this.handleCodeChange(evt.target.value)}
                                    type="text"
                                    placeholder="Bestätigungscode"
                                    required={true}/>
                            </Box>
                        </div>
                    </form>
                    <p
                        className={signUpConfirmStyles.resend}
                        onClick={this.resendCode}>
                        Neuen Code anfordern
                    </p>
                    <div className={signUpConfirmStyles.message}>
                        {this.renderLoading()}
                        {this.renderInfo()}
                        {this.renderError()}
                    </div>
                    <Box
                        display="flex"
                        justifyContent="center">
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            disabled={!this.state.buttonEnabled}
                            className={"button"}
                            onClick={this.onCodeSubmit}>
                            Absenden
                        </Button>
                    </Box>

                </div>
            </React.Fragment>
        );
    }

    private renderLoading() {
        if (!this.state.loading)
            return null;
        return (
            <span
                id="message-id"
                style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                <CircularProgress
                    className={"progress"}
                    color="inherit"
                    size={20}/>
                Verarbeite...
            </span>
        )
    }

    private renderInfo() {
        if (this.state.infoMessage === "")
            return null;
        return (
            <p className={signUpConfirmStyles.info}>
                {this.state.infoMessage}
            </p>
        );
    }

    private renderError() {
        if (this.state.errorMessage === "")
            return null;
        return (
            <p className={signUpConfirmStyles.error}>
                {this.state.errorMessage}
            </p>
        );
    }

    private onCodeSubmit() {
        this.setState({
            errorMessage: "",
            infoMessage: "",
            loading: true,
            buttonEnabled: false
        });
        Auth.confirmSignUp(this.props.username, this.state.confirmationCode)
            .then(() => {
                this.props.stageComplete();
            }).catch(err => {
                this.setState({
                    loading: false,
                    buttonEnabled: true,
                    infoMessage: "",
                    errorMessage: SignupConfirmation.getMessageForError(err)
                });
            });
    }

    private resendCode() {
        this.setState({loading: true});
        Auth.resendSignUp(this.props.username)
            .then(() => {
                this.setState({
                    loading: false,
                    errorMessage: "",
                    infoMessage: "Ein neuer Code wurde an deine E-Mail-Addresse gesendet"
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    infoMessage: "",
                    errorMessage: SignupConfirmation.getMessageForError(err)
                });
            });
    }

    private handleCodeChange(code: string) {
        const regex = RegExp("^[0-9]*$");
        if (!regex.test(code))
            return;
        this.setState({
            buttonEnabled: code.length === VERIFICATION_CODE_LENGTH,
            confirmationCode: code
        });
    }

    private static getMessageForError(err: any): string {
        if (err.code === ERRORCODE_INVALID_CODE)
            return "Der eingegebene Code ist ungültig. Versuche es erneut!"
        return `Es ist ein Fehler aufgetreten: ${err.message}`;
    }

}
