import React from "react";
import ProjectInfo from "../../model/ProjectInfo";
import ProjectInfoComponent from "./ProjectInfoComponent";
import {loadProjectInfos} from "../../control/ProjectInfoLoader";
import FooterComponent from "../FooterComponent";
import projectInfoStyles from "../../styles/ProjectInfoStyle.module.css"

interface State {
    projectInfos: ProjectInfo[]
}

export default class ProjectComponent extends React.PureComponent<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            projectInfos: []
        };
    }

    componentDidMount() {
        loadProjectInfos().then((infos: ProjectInfo[]) => {
            this.setState({
                projectInfos: infos
            });
        });
    }

    render() {
        return (
            <div className={projectInfoStyles.rootContainer}>
                {this.renderProjectInfos()}
                <FooterComponent/>
            </div>
        )
    }

    private renderProjectInfos() {
        return this.state.projectInfos.map(info =>
            <ProjectInfoComponent
                key={info.projectName}
                projectInfo={info}/>
        );
    }
}
