import React from "react";
import {Link} from "react-router-dom";
import {ROUTE_BASE} from "../../control/routes";
import {CircularProgress} from "@material-ui/core";

import baseStyles from "../../styles/BaseStyle.module.css";
import verificationStyles from "../../styles/VerificationStyle.module.css";

import {EmailVerificationResult, verifyEmailAddress} from "../../control/verification";
import {queryParametersPresent} from "../../control/query";

enum VerificationState {
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    IN_USE = "IN_USE",
    EXPIRED = "EXPIRED",
    INVALID = "INVALID",
    ERROR = "ERROR"
}

interface State {
    verificationState: VerificationState
}

export default class VerificationComponent extends React.PureComponent<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            verificationState: VerificationState.PENDING
        };
    }

    componentDidMount() {
        if (!queryParametersPresent()) {
            window.location.assign(window.location.origin);
            return;
        }
        verifyEmailAddress()
            .then(result => {
                let verificationState;
                switch (result) {
                    case EmailVerificationResult.VERIFIED:
                        verificationState = VerificationState.SUCCESS
                        break;
                    case EmailVerificationResult.INVALID:
                        verificationState = VerificationState.INVALID;
                        break;
                    case EmailVerificationResult.IN_USE:
                        verificationState = VerificationState.IN_USE;
                        break;
                    case EmailVerificationResult.EXPIRED:
                        verificationState = VerificationState.EXPIRED;
                        break;
                    case EmailVerificationResult.ERROR:
                        verificationState = VerificationState.ERROR;
                        break;
                }
                this.setState({
                    verificationState: verificationState
                });
            })
    }

    render() {
        return (
            <div className={verificationStyles.verificationContainer}>
                {this.renderMessage()}
                {this.renderHomeLink()}
            </div>
        );
    }

    private renderHomeLink(): JSX.Element | null {
        if (this.state.verificationState === VerificationState.PENDING)
            return null;
        return (
            <Link
                className={baseStyles.link}
                to={ROUTE_BASE}>
                Zur Startseite
            </Link>
        );
    }

    private renderMessage(): JSX.Element {
        switch (this.state.verificationState) {
            case VerificationState.PENDING:
                return VerificationComponent.renderPendingState();
            case VerificationState.SUCCESS:
                return VerificationComponent.renderSuccessState();
            case VerificationState.IN_USE:
                return VerificationComponent.renderInUseState();
            case VerificationState.EXPIRED:
                return VerificationComponent.renderExpiredState();
            case VerificationState.INVALID:
                return VerificationComponent.renderInvalidState();
            case VerificationState.ERROR:
                return VerificationComponent.renderErrorState();
        }
    }

    private static renderPendingState(): JSX.Element {
        return (
            <div className={verificationStyles.verificationLoadingContainer}>
                <span className={baseStyles.loadingInfo}>
                    <CircularProgress
                        className={baseStyles.progress}
                        color="inherit"
                        size={20}/>
                    <h3 className={verificationStyles.verificationLoading}>Verifiziere E-Mail-Adresse...</h3>
                </span>
            </div>
        );
    }

    private static renderSuccessState(): JSX.Element {
        const headline = "Verifikation erfolgreich! :)";
        const message = "Deine E-Mail-Adresse wurde erfolgreich verifiziert.";
        const info = "Du kannst dich jetzt nur noch mit deiner neuen Adresse anmelden.";
        return VerificationComponent.renderMessage(headline, message, info);
    }

    private static renderInUseState(): JSX.Element {
        const headline = "E-Mail in Gebrauch. :-/";
        const message = "Diese E-Mail-Adresse wird bereits von einem anderen Nutzer verwendet";
        const info = "Deine Adresse konnte nicht aktualisiert werden. Bitte wähle eine andere!";
        return VerificationComponent.renderMessage(headline, message, info);
    }

    private static renderExpiredState(): JSX.Element {
        const headline = "Zu spät! :(";
        const message = "Der Verifikationslink ist leider abgelaufen.";
        const info = "Du musst deine E-Mail-Adresse erneut aktualisieren um einen neuen Link zu erhalten.";
        return VerificationComponent.renderMessage(headline, message, info);
    }

    private static renderInvalidState(): JSX.Element {
        const headline = "Ungültiger Link! :/";
        const message = "Dieser Verifikationslink ist nicht gültig, oder konnte nicht zugeordnet werden.";
        const info = "Prüfe den Link auf Tippfehler und versuche es erneut!";
        return VerificationComponent.renderMessage(headline, message, info);
    }

    private static renderErrorState(): JSX.Element {
        const headline = "Es ist ein Fehler aufgetreten! :-O";
        const message = "Möglicherweise wurde deine E-Mail-Adresse nicht korrekt verifiziert.";
        const info = "Bitte wende dich an den Support!";
        return VerificationComponent.renderMessage(headline, message, info);
    }

    private static renderMessage(headline: string, message: string, info: string): JSX.Element {
        return (
            <React.Fragment>
                <h2 className={verificationStyles.verificationHeadline}>{headline}</h2>
                <p className={verificationStyles.verificationMessage}>{message}</p>
                <p className={verificationStyles.verificationInfo}>{info}</p>
            </React.Fragment>
        )
    }

}
