import {ProjectInfoLoader} from "./ProjectInfoLoader";
import ProjectInfo from "../model/ProjectInfo";
import projects from "../data/projects.json";

const PICTURE_BASE_PATH = "/img";

export default class BundleProjectInfoLoader implements ProjectInfoLoader {

    getProjectPicturePath(info: ProjectInfo): string {
        const imgName = `${info.projectName.toLowerCase().replace(" ", "_")}.png`;
        return `${PICTURE_BASE_PATH}/${imgName}`;
    }

    loadProjectInfos(): Promise<ProjectInfo[]> {
        return Promise.resolve(projects);
    }

}