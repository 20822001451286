import React from "react";
import {Box, Button, Input} from "@material-ui/core";
import {Auth} from "aws-amplify";

import changeFormStyles from "../../styles/ChangeFormStyle.module.css"
import {validatePasswords} from "../../control/validation";

interface Props {
    passwordChangeCompleted: (originURL: string) => void
    waitingForResponse: () => void
    handleError: (error: Error) => void
    username: string
    token: string
    originURL: string
}

interface State {
    password: string
    passwordConfirm: string
    saveButtonEnabled: boolean
}

export default class ChangeForm extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            password: "",
            passwordConfirm: "",
            saveButtonEnabled: true
        }
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePasswordConfirmChange = this.handlePasswordConfirmChange.bind(this);
        this.onPasswordChangedClicked = this.onPasswordChangedClicked.bind(this);
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <form autoComplete={"off"}>
                        <div>
                            <label className={changeFormStyles.label}>Passwort *</label>
                            <Box width={300}>
                                <Input
                                    className={"input"}
                                    fullWidth={true}
                                    onChange={evt => this.handlePasswordChange(evt.target.value)}
                                    type="password"
                                    placeholder="Passwort"
                                    required={true}/>
                            </Box>
                        </div>
                        <div>
                            <label className={changeFormStyles.label}>Passwort wiederholen *</label>
                            <Box width={300}>
                                <Input
                                    className={"input"}
                                    fullWidth={true}
                                    onChange={evt => this.handlePasswordConfirmChange(evt.target.value)}
                                    type="password"
                                    placeholder="Passwort wiederholen"
                                    required={true}/>
                            </Box>
                        </div>
                        <Box className={changeFormStyles.box}
                             display="flex"
                             justifyContent="center">
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                disabled={!this.state.saveButtonEnabled}
                                className={"button"}
                                onClick={this.onPasswordChangedClicked}>
                                Neues Passwort speichern
                            </Button>
                        </Box>
                    </form>
                </div>
            </React.Fragment>
        );
    }

    private handlePasswordChange(password: string) {
        this.setState({
            password: password
        });
    }

    private handlePasswordConfirmChange(passwordConfirm: string) {
        this.setState({
            passwordConfirm: passwordConfirm
        });
    }

    private onPasswordChangedClicked() {
        this.props.waitingForResponse()
        try {
            validatePasswords(this.state.password, this.state.passwordConfirm);
            this.changePassword();
        } catch (err) {
            this.props.handleError(err)
        }
    }

    private changePassword() {
        this.disableSavePasswordButton()
        this.sendForgotPasswordSubmit();
    }

    private sendForgotPasswordSubmit() {
        Auth.forgotPasswordSubmit(
            this.props.username,
            this.props.token,
            this.state.password
        ).then(() => {
            this.props.passwordChangeCompleted(this.props.originURL);
        }).catch(err => {
            this.props.handleError(err)
            this.enableSavePasswordButton()
        });
    }


    private disableSavePasswordButton() {
        this.setState({
            saveButtonEnabled: false
        });
    }

    private enableSavePasswordButton() {
        this.setState({
            saveButtonEnabled: true
        });
    }

}