import React from 'react';
import './App.css';
import MainComponent from "./view/MainComponent";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from '@material-ui/styles';
import maternaTheme from "./styles/materna_theme";

function App() {
  return (
      <BrowserRouter>
          <ThemeProvider theme={maternaTheme}>
            <MainComponent/>
          </ThemeProvider>
      </BrowserRouter>
  );
}

export default App;
