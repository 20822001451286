const ENVIRONMENT_PRODUCTION = "production";
const ENVIRONMENT_STAGING = "staging";
const ENVIRONMENT_DEVELOPMENT = "development";

export function convertURLByEnvironment(url: string) {
    const environment = getEnvironment();
    switch (environment) {
        case ENVIRONMENT_DEVELOPMENT:
        case ENVIRONMENT_STAGING:
            return `https://${url.split(".")[0]}.2gether-staging.rocks`;
        case ENVIRONMENT_PRODUCTION:
            return `https://${url}`;
        default:
            return url;
    }
}

function getEnvironment(): string | undefined {
    return process.env.REACT_APP_ENVIRONMENT;
}