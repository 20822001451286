import React from "react";
import ProjectInfo from "../../model/ProjectInfo";
import {getProjectPicturePath} from "../../control/ProjectInfoLoader";
import {convertURLByEnvironment} from "../../control/environment";
import baseStyles from "../../styles/BaseStyle.module.css"
import projectInfoStyles from "../../styles/ProjectInfoStyle.module.css"

interface Props {
    projectInfo: ProjectInfo
}

export default class ProjectInfoComponent extends React.PureComponent<Props> {

    render() {
        return (
            <div className={projectInfoStyles.infoContainer}>
                <h2 className={projectInfoStyles.headline}>
                    {this.props.projectInfo.projectName}
                </h2>
                <div>
                    <img
                        className={projectInfoStyles.image}
                        src={getProjectPicturePath(this.props.projectInfo)}
                        alt={`img:${this.props.projectInfo.projectName}`} />
                    <p className={projectInfoStyles.infoText}>
                        {this.props.projectInfo.projectDescription}
                    </p>
                    {this.renderProjectLinkContainer()}
                </div>
            </div>
        );
    }

    private renderProjectLinkContainer(){
        if(this.props.projectInfo.displayProjectLink){
            return (this.props.projectInfo.alternativeProjectLink ? this.renderTwoProjectLinks() : this.renderOneProjectLink())
        } else {
            return(
                <div className={projectInfoStyles.linkContainer}>
                    {(this.props.projectInfo.apologyTextIfLinkNotDisplayed) ?
                        this.props.projectInfo.apologyTextIfLinkNotDisplayed :
                        this.props.projectInfo.projectName + " ist zur Zeit nicht verfügbar."}
                </div>
            )
        }
    }

    private renderTwoProjectLinks() {
        return(
            <div className={projectInfoStyles.linkContainer}>
                    <a
                        className={baseStyles.altlink}
                        href={convertURLByEnvironment(this.props.projectInfo.projectLink)}>
                        Zur Webseite
                    </a>
                    <a
                        className={baseStyles.altlink}
                        href={convertURLByEnvironment(this.props.projectInfo.alternativeProjectLink!!)}>
                        Alternative Webseite
                    </a>
            </div>
        )
    }

    private renderOneProjectLink() {
        return(
            <a
                className={baseStyles.link}
                href={convertURLByEnvironment(this.props.projectInfo.projectLink)}>
                Zur Webseite
            </a>
        )
    }
}
