import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const maternaTheme = createMuiTheme({
    palette: {
        primary: { main: '#c30a17' },
        secondary: { main: '#3a3E41' },
    },
});

export default maternaTheme;
