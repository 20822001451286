enum AwsErrors {
    CODE_DELIVERY_FAILURE = "CodeDeliveryFailureException",
    CODE_MISMATCH = "CodeMismatchException",
    EXPIRED_CODE = "ExpiredCodeException",
    INTERNAL_ERROR = "InternalErrorException",
    INVALID_EMAIL_ROLE_ACCESS_POLICY = "InvalidEmailRoleAccessPolicyException",
    INVALID_LAMBDA_RESPONSE = "InvalidLambdaResponseException",
    INVALID_PARAMETER = "InvalidParameterException",
    INVALID_PASSWORD = "InvalidPasswordException",
    INVALID_SMS_ROLE_ACCESS_POLICY = "InvalidSmsRoleAccessPolicyException",
    INVALID_SMS_ROLE_TRUST_RELATIONSHIP = "InvalidSmsRoleTrustRelationshipException",
    LIMIT_EXCEEDED = "LimitExceededException",
    NOT_AUTHORIZED = "NotAuthorizedException",
    RESOURCE_NOT_FOUND = "ResourceNotFoundException",
    TOO_MANY_FAILED_ATTEMPTS = "TooManyFailedAttemptsException",
    TOO_MANY_REQUESTS = "TooManyRequestsException",
    UNEXPECTED_LAMBDA = "UnexpectedLambdaException",
    USERNAME_EXISTS = "UsernameExistsException",
    USER_LAMBDA_VALIDATION = "UserLambdaValidationException",
    USER_NOT_CONFIRMED = "UserNotConfirmedException",
}

export enum CustomErrors {
    PASSWORDS_NOT_EQUAL = "PasswordsNotEqualError",
    PASSWORD_INVALID = "PasswordInvalidError",
    INVALID_FIRST_NAME = "InvalidFirstNameError",
    INVALID_LAST_NAME = "InvalidLastNameError",
    MATTERMOST_USERNAME_INVALID = "MattermostUsernameInvalidError",
    MATTERMOST_USERNAME_RESERVED = "MattermostUsernameReservedError",
    EMAIL_INVALID = "EmailInvalidError",
    QUERY_PARAMS_INVALID = "QueryParamsInvalidError",
}

const STANDARD_ERROR_MESSAGE = "Unbekannter Fehler"


export function getCustomErrorMessage(err: Error): string {

    if (isCustomError(err)) {
        return err.message
    }

    switch (err.name) {
        case AwsErrors.CODE_DELIVERY_FAILURE:
            return "Verifizierungscode konnte nicht erfolgreich gesendet werden."
        case AwsErrors.CODE_MISMATCH:
            return "Unbekannter Code"
        case AwsErrors.EXPIRED_CODE:
            return "Der Link zum Zurücksetzen ist abgelaufen. Erstelle einen neuen!"
        case AwsErrors.INTERNAL_ERROR:
            return STANDARD_ERROR_MESSAGE
        case AwsErrors.INVALID_EMAIL_ROLE_ACCESS_POLICY:
            return STANDARD_ERROR_MESSAGE
        case AwsErrors.INVALID_LAMBDA_RESPONSE:
            return STANDARD_ERROR_MESSAGE
        case AwsErrors.INVALID_PARAMETER:
            return "Falscher Parameter"
        case AwsErrors.INVALID_PASSWORD:
            return "Falsches Passwort"
        case AwsErrors.INVALID_SMS_ROLE_ACCESS_POLICY:
            return STANDARD_ERROR_MESSAGE
        case AwsErrors.INVALID_SMS_ROLE_TRUST_RELATIONSHIP:
            return STANDARD_ERROR_MESSAGE
        case AwsErrors.LIMIT_EXCEEDED:
            return "Zu viele Anfragen auf diese Ressource!"
        case AwsErrors.NOT_AUTHORIZED:
            return "Fehlende Authorisierung!"
        case AwsErrors.RESOURCE_NOT_FOUND:
            return STANDARD_ERROR_MESSAGE
        case AwsErrors.TOO_MANY_FAILED_ATTEMPTS:
            return "Zu viele fehlgeschlagene Anfragen!"
        case AwsErrors.TOO_MANY_REQUESTS:
            return "Zu viele Anfragen"
        case AwsErrors.UNEXPECTED_LAMBDA:
            return STANDARD_ERROR_MESSAGE
        case AwsErrors.USERNAME_EXISTS:
            return "Der Benutzername ist schon vergeben!"
        case AwsErrors.USER_LAMBDA_VALIDATION:
            return STANDARD_ERROR_MESSAGE
        case AwsErrors.USER_NOT_CONFIRMED:
            return "Der Benutzer wurde nicht gefunden!"
        default:
            return STANDARD_ERROR_MESSAGE
    }

    function isCustomError(err: Error) {
        return Object.values(CustomErrors).includes(err.name as CustomErrors);
    }

}

