import React from "react";
import {Route, Switch} from "react-router-dom";
import ProjectComponent from "./project/ProjectComponent";
import {ROUTE_BASE, ROUTE_VERIFICATION, ROUTE_SIGNUP,ROUTE_CHANGE_PASSWORD,ROUTE_NOT_FOUND} from "../control/routes";
import SignupComponent from "./signup/SignupComponent";
import HeaderComponent from "./HeaderComponent";
import VerificationComponent from "./verification/VerificationComponent";
import ChangeComponent from "./change/ChangeComponent";

export default class MainComponent extends React.PureComponent {

    render() {
        return (
            <React.Fragment>
                <HeaderComponent/>
                <Switch>
                    <Route exact path={ROUTE_BASE} component={ProjectComponent}/>
                    <Route path={ROUTE_SIGNUP} component={SignupComponent}/>
                    <Route path={ROUTE_VERIFICATION} component={VerificationComponent}/>
                    <Route path={ROUTE_CHANGE_PASSWORD} component={ChangeComponent}/>
                    <Route path={ROUTE_NOT_FOUND} component={ProjectComponent}/>
                </Switch>
            </React.Fragment>
        );
    }

}