import React from "react";
import {CircularProgress} from "@material-ui/core";

import baseStyles from "../../styles/BaseStyle.module.css";
import changeStyle from "../../styles/ChangeStyle.module.css";

import {ChangePasswordQueryParams, extractQueryParameters} from "../../control/changePassword";
import {queryParametersPresent} from "../../control/query";
import ChangeForm from "./ChangeForm";
import {getCustomErrorMessage} from "../../control/ErrorHandler";

enum MessageType {
    NONE = "NONE",
    LOADING = "LOADING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

interface State {
    changePasswordState: MessageType
    errorMessage: string
    changePasswordQueryParams: ChangePasswordQueryParams | null
}

export default class ChangeComponent extends React.PureComponent<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            changePasswordState: MessageType.NONE,
            errorMessage: "",
            changePasswordQueryParams: null,
        }
        this.passwordChangeCompleted = this.passwordChangeCompleted.bind(this)
        this.waitingForResponse = this.waitingForResponse.bind(this)
        this.handleError = this.handleError.bind(this)
    }

    componentDidMount() {
        if (!queryParametersPresent()) {
            window.location.assign(window.location.origin);
            return;
        }
        try {
            const queryParameters = extractQueryParameters()
            this.setState({
                changePasswordQueryParams: queryParameters
            });
        } catch (err) {
            this.handleError(err)
        }
    }

    render() {
        return (
            <div>
                {this.renderChangeForm()}
                {this.renderStateMessage()}
            </div>
        );
    }


    private renderChangeForm(): JSX.Element | null {
        if (!this.hasValidParamsForChangingPassword()) {
            return null;
        }
        const token = this.state.changePasswordQueryParams!!.token
        const username = this.state.changePasswordQueryParams!!.userID
        const originURL = this.state.changePasswordQueryParams!!.originURL
        return (<div className={changeStyle.container}>
                <ChangeForm
                    passwordChangeCompleted={this.passwordChangeCompleted}
                    waitingForResponse={this.waitingForResponse}
                    handleError={this.handleError}
                    token={token}
                    username={username}
                    originURL={originURL}
                />
            </div>
        );
    }

    private hasValidParamsForChangingPassword() {
        return this.state.changePasswordQueryParams != null
    }

    private waitingForResponse() {
        this.setState({
            changePasswordState: MessageType.LOADING
        })
    }


    private passwordChangeCompleted(originURL: string) {
        this.setState({
            changePasswordState: MessageType.SUCCESS
        })
        setTimeout(function () {
            window.location.replace("//" + originURL)
        }, 1000);
    }

    private renderStateMessage(): JSX.Element | null {
        switch (this.state.changePasswordState) {
            case MessageType.NONE:
                return null;
            case MessageType.LOADING:
                return ChangeComponent.renderPendingState();
            case MessageType.SUCCESS:
                return this.renderSuccessState();
            case MessageType.ERROR:
                return this.renderErrorState(this.state.errorMessage);
            default:
                return null;
        }
    }

    private handleError(err: Error) {
        const errorMessage = getCustomErrorMessage(err)
        this.setState({
            errorMessage: errorMessage,
            changePasswordState: MessageType.ERROR
        })
    }

    private static renderPendingState(): JSX.Element {
        return (
            <div className={changeStyle.loadingContainer}>
                <span className={baseStyles.loadingInfo}>
                    <CircularProgress
                        className={baseStyles.progress}
                        color="inherit"
                        size={20}/>
                    <h3 className={changeStyle.changeLoading}>Ändere dein Passwort...</h3>
                </span>
            </div>
        );
    }

    private renderSuccessState(): JSX.Element {
        const headline = "Ändern erfolgreich! :)";
        const message = "Dein Passwort wurde erfolgreich geändert.";
        const info = "Du wirst nun auf die Anmeldeseite weitergeleitet.";
        return this.renderMessage(headline, message, info);
    }

    private renderErrorState(errorMessage: string): JSX.Element {
        const headline = "Passwort konnte nicht gespeichert werden";
        return this.renderMessage(headline, errorMessage);
    }

    private renderMessage(headline: string, message: string, info?: string): JSX.Element {
        const hasInfoText = info !== undefined
        const className = this.getMessageContainerClassName()
        console.log(className)
        return (
            <div className={className}>
                <React.Fragment>
                    <h2 className={changeStyle.headline}>{headline}</h2>
                    <p className={changeStyle.message}>{message}</p>
                    {hasInfoText && <p className={changeStyle.info}>{info}</p>}
                </React.Fragment>
            </div>
        )
    }

    private getMessageContainerClassName() {
        if (this.hasValidParamsForChangingPassword()) {
            return changeStyle.messageContainer;
        } else {
            return changeStyle.messageContainerWithHeaderSpace;
        }
    }

}
