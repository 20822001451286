import {getQueryParams} from "./query";

export enum EmailVerificationResult {
    VERIFIED = "VERIFIED",
    INVALID = "INVALID",
    IN_USE = "IN_USE",
    EXPIRED = "EXPIRED",
    ERROR = "ERROR"
}

const STATUS_VERIFIED = 200;
const STATUS_INVALID = 400;
const STATUS_IN_USE = 409;
const STATUS_EXPIRED = 410;
const STATUS_ERROR = 500;

const VERIFICATION_URL = process.env.REACT_APP_VERIFICATION_URL;
const PARAM_USERID = "userID";
const PARAM_TOKEN = "token";


export async function verifyEmailAddress(): Promise<EmailVerificationResult> {
    const params = getQueryParams();
    if (!validateQueryParams(params))
        return EmailVerificationResult.INVALID;
    const url = getRequestURL(params);
    const response = await fetch(url);
    const result = getResultFromStatus(response.status);
    return result;
}

function validateQueryParams(params: Map<string, string>) {
    return params.size === 2 && params.has(PARAM_USERID) && params.has(PARAM_TOKEN);
}

function getRequestURL(params: Map<string, string>): string {
    const userID = params.get(PARAM_USERID);
    const token = params.get(PARAM_TOKEN);
    return `${VERIFICATION_URL}?${PARAM_USERID}=${userID}&${PARAM_TOKEN}=${token}`;
}

function getResultFromStatus(status: number): EmailVerificationResult {
    switch (status) {
        case STATUS_VERIFIED:
            return EmailVerificationResult.VERIFIED;
        case STATUS_INVALID:
            return EmailVerificationResult.INVALID;
        case STATUS_IN_USE:
            return EmailVerificationResult.IN_USE;
        case STATUS_EXPIRED:
            return EmailVerificationResult.EXPIRED;
        case STATUS_ERROR:
            return EmailVerificationResult.ERROR;
        default:
            return EmailVerificationResult.ERROR;
    }
}